<template>
  <!--正在播放-->
  <div class="playList">
    <music-list
      :list="playlist"
      list-type="duration"
      @select="selectItem"
      @del="deleteItem"
    >
    </music-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import MusicList from 'components/music-list/music-list'

export default {
  name: 'PlayList',
  components: {
    MusicList
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters(['playing', 'playlist', 'currentMusic'])
  },
  methods: {
    // 播放暂停事件
    selectItem(item, index) {
      if (item.id !== this.currentMusic.id) {
        this.setCurrentIndex(index)
        this.setPlaying(true)
      }
    },
    // 删除事件
    deleteItem(index) {
      let list = [...this.playlist]
      list.splice(index, 1)
      this.removerPlayListItem({ list, index })
      this.$Toast('删除成功')
    },
    ...mapMutations({
      setPlaying: 'SET_PLAYING',
      setCurrentIndex: 'SET_CURRENTINDEX',
      clearPlaylist: 'CLEAR_PLAYLIST'
    }),
    ...mapActions(['removerPlayListItem'])
  }
}
</script>

<style lang="less">
.playList {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.8s;
  .musicList {
    width: 100%;
    height: 100%;
    .list-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      span {
        padding: 5px 20px;
        cursor: pointer;
        user-select: none;
        &:hover {
          color: @text_color_active;
        }
      }
    }
  }
}
::-webkit-scrollbar-thumb {
  visibility: hidden;
  background: #757F9A;
  background: -webkit-linear-gradient(to right, #D7DDE8, #757F9A);
  background: linear-gradient(to right, #D7DDE8, #757F9A);
  border-radius: 10px;
  transition: all 0.8s;
}
.playList:hover ::-webkit-scrollbar-thumb {
  visibility: visible;
}
</style>
